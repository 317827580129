import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import BgImage from "../../static/img/bg3cropped.jpg"
import SssImage from "../../static/img/sss-1.png"
import CetsImage from "../../static/img/cets.png"
import HabitatForHumanity from "../../static/img/habitat-for-humanity.png"
import MedicalBridges from "../../static/img/medical-bridges.png"

const ImpactPage = () => (
  <Layout>
    <Parallax bgImage={BgImage} className="text-center parallax-secondary">
      <div className="container-fluid">
        <div className="row">
          <div className="col"></div>
          <div className="col-md-7">
            <h1 className="text-white wow fadeInDown">IMPACT</h1>
            <h3 className="text-white wow fadeInUp subtitle">
              Salvex is a global auction platform that handles the sale and
              repurposing of commercial surplus, salvage, scrap, damaged,
              bankrupt inventories for asset recovery, insurance,
              transportation, manufacturing and oil and gas industries
            </h3>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </Parallax>
    <div className="container-fluid pb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col pt-4">
            <h2 className="text-primary mt-4 text-center wow fadeInUp">
              Salvex Sustainability Solutions
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-md-8 wow fadeInLeft">
            <p className="mt-4 mb-4 text-secondary text-center">
              The Salvex Sustainability Technology links teams of people with
              sophisticated software, hardware and process to complete complex
              international transactions and create supply chain solutions.
            </p>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-md-8 wow fadeInRight">
            <p className="mt-4 mb-4 text-center">
              <img
                src={SssImage}
                alt="Sustainability Solutions"
                className="img-fluid"
              />
            </p>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-alt pt-4 pb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col"></div>
          <div className="col-md-8">
            <p className="mt-4 mb-4 wow fadeInLeft">
              Salvex creates <strong>Sustainability Solutions</strong> for major
              corporations globally through surplus inventory asset recovery
              sales, develop comprehensive recycling solutions, and introduce
              economical solar solutions. The objective is to reduce a company's
              impact on the environment, through inventory management, waste
              reduction and utilization of renewable energy. We work with your
              procurement departments, inventory managers, warehouse managers,
              logistics managers, accounting, and sustainability professionals
              to identify internal solutions and recommend immediate
              improvements and policy changes. Once the improvements are in
              place, we provide the case studies and quantify the results which
              can be projected internally and externally.
            </p>
            <p className="mt-4 mb-4 wow fadeInLeft">
              Salvex's success is achieved by utilizing teams of people
              including engineers, claims professionals, traders, commodity
              experts, recycling specialists, and solar experts to work with
              multiple people within the organization.
            </p>
            <ul className="mt-4 mb-4 wow fadeInRight">
              <li>
                Sustainability Professionals&trade; build solutions by
                optimizing a company’s inventory and monetizing underutilized
                assets on global markets to reduce costs, increase revenue and
                recirculate usable assets.
              </li>
              <li>
                Global Circular Economy is achieved by maximizing value from
                corporate assets until the end of their service lives. The
                global circular economy is a regenerative economic system that
                reuses assets once perceived as waste.
              </li>
              <li>
                Corporate Shared Economy is implemented by leveraging a Business
                to Entrepreneur (B-to-E) model to trade assets globally. The
                global shared economy consists of corporations and individuals
                who share product and regional understandings to ensure
                successful global transactions of complicated commercial assets.
              </li>
            </ul>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>

    <div className="container-fluid pt-4 pb-4 border-bottom">
      <div className="container mb-4">
        <div className="row">
          <div className="col pt-4">
            <h2 className="text-primary mt-4 text-center wow fadeInUp">
              CHARITIES THAT SUPPORT THE CIRCULAR ECONOMY
            </h2>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col"></div>
          <div className="col-sm-8 col-md-3 col-lg-2 text-center wow fadeInLeft">
            <img
              src={MedicalBridges}
              alt="Medical Bridges"
              className="img-fluid"
            />
          </div>
          <div className="col-sm-8 col-md-5 col-lg-6 text-center wow fadeInRight">
            <p className="mt-4 mb-4 text-secondary">
              Medical Bridges closes the healthcare gap worldwide by procuring
              and distributing medical equipment and supplies to underserved
              communities.
            </p>
          </div>
          <div className="col"></div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col"></div>
          <div className="col-sm-8 col-md-5 col-lg-6 text-center wow fadeInLeft">
            <p className="mt-4 mb-4 text-secondary">
              Habitat for Humanity is a global nonprofit housing organization
              working in local communities across all 50 states in the U.S. and
              in approximately 70 countries. Habitat’s vision is of a world
              where everyone has a decent place to live.
            </p>
          </div>
          <div className="col-sm-8 col-md-3 col-lg-2 text-center wow fadeInRight">
            <img
              src={HabitatForHumanity}
              alt="Habitat for Humanity"
              className="img-fluid"
            />
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ImpactPage
